import React, { Component } from 'react'
import { Link } from "react-router-dom";
import LeftNav from "../components/LeftNav"

export default class Taccuini extends Component {

    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    {window.innerWidth > 1120 ?
                        <div>
                            <div style={{padding: "50px 50px 0 50px"}}>
                                <p className="collectionTitle">
                                    Taccuini
                                </p>
                                <div className="coupleCollections">
                                    <div className="collectionLeft">
                                    <img alt='' src={'/images/taccuini/firma1.jpg'} className="pieceImgGen" />
                                    </div>
                                    <div className="collectionRight">
                                    <img alt='' src={'/images/taccuini/firma2.jpg'} className="pieceImgGen" />
                                    </div>
                                </div>
                                <p>
                                    Algorithms are not only meant for computers. To study the concept and experiment on the potential of the series I work on, I often draw by hand.
                                    Taccuini is a series of physical notebooks, each fully dedicated to one single algorithm of <Link to="/ricercasemica">Ricerc(a)semica</Link> in a given year.<br/>
                                    <a href="https://www.transient.xyz/nfts/base/0xd1d00954d6deb4c3186cf0dce97a69fe1e249be6/1" target="_blank" rel="noopener noreferrer">Firma '24</a> is the first notebook that was created with this intent, authenticated with T.R.A.C.E. from TransientLabs.
                                    <br/><br/>
                                    Schneider Topball 847 on Fabriano Ispira soft cover.
                                </p>
                                <p>
                                    For more information or to purchase <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer"> encapsuled.art@gmail.com</a>
                                </p>
                                <br/><br/>
                            </div>
                        </div>
                    :
                        <div className="collectionLeft">
                            <p className="collectionTitle">
                                Taccuini
                            </p>
                            <img alt='' src={'/images/taccuini/firma1.jpg'} className="pieceImgGen" />
                            <img alt='' src={'/images/taccuini/firma2.jpg'} className="pieceImgGen" />
                            <p>
                                Linebreak is the story of a curious line.<br/><br/>
                                The algorithm for this series narrates this story in two parallel ways: 
                                every visual output is paired with some verses that can be read by (T)oggling with the keyboard, 
                                and the whole poetic story can be (D)ownloaded.<br/><br/>
                                In the visual outputs, the line breaks and evolves,
                                almost like following the birth of a language with increasing degrees of freedom and complexity.
                                The verses in their entirety tell a story that has multiple interpretations.
                                The theme of interpreting and using the person's own creativity is synergic,
                                since the visual outputs feature an asemic language I crafted over time.
                                Or better, not asemic, but multisemic, since it has meaning but it's up to each viewer to derive it.<br/><br/>
                                Imagination is closely tied to perception, the more we experience,
                                the more power our mind has to create new concepts by assembling existing ones.
                                It's only by feeding more perceptions to ourselves that we can enhance our creativity,
                                and learn to interpret our perceptions in different ways. The same can be applied to reading.
                                The more we're used to think about what we are consuming the more we will be able to get information out of it.
                            </p>
                            <br/><br/>
                        </div>
                    }
                </div>
            </div>
        )
    }
};