import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Ricercasemica extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Ricerc(a)semica
                            </p>
                            <br/><br/>
                            <p>
                                Il significato è la ricerca.<br/>
                                Significato proprio delle cose<br/>
                                no<br/>
                                significato proprio del linguaggio<br/>
                                parte del linguaggio<br/>
                                dipendente dal linguaggio<br/>
                                proprietà del linguaggio.<br/>
                                Comunicazione come vettore di significato<br/>
                                nel senso che porta il significato<br/>
                                ma anche<br/>
                                che il significato non può essere portato<br/>
                                senza di essa.<br/>
                                Significato individuale per diventare condiviso,<br/>
                                carattere, linea, testo, compressione ed evoluzione.<br/>
                                Ricerche ad ogni livello per raggiungerne un altro,<br/>
                                un anello<br/>
                                o meglio<br/>
                                una spirale.<br/>
                                Ritorno con aggiunta per ripartire,<br/>
                                di nuovo,<br/>
                                in altre ricerche<br/>
                                parte della stessa ricerca<br/>
                                l’unica possibilità.
                            </p>
                            <br/><br/>
                            <p>
                                Ricerc(a)semica è il risultato di una prima immersione nel problema del significato.<br/>
                                Lontano ancora da offrire risposte, è in fase di suggerimenti e proposte: esplorazioni visive di un 
                                linguaggio non leggibile, ritmico e melodico risultato di regole definite attraverso codice.
                                Un linguaggio creato da un linguaggio di programmazione per esplorare i limiti del linguaggio comune. 
                                Un modo per restituire parte del controllo e instaurare un nuovo equilibrio di significato tra scrittore e lettore.
                            </p>
                            <br/><br/>
                            <p>
                                Le venti opere che seguono sono uniche iterazioni di algoritmi scritti da me, disegnati dal mio pen plotter con una Schneider Topball 847 su carta telata in formato A3, firmati sul retro e incorniciati manualmente a vivo con legno naturale.
                                Per maggiori informazioni o acquisto <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer"> encapsuled.art@gmail.com</a>
                            </p>
                            <br/><br/>
                            <p className="collectionTitle">
                                Carattere
                            </p>
                            <img
                                src='/images/ricercasemica/char1.svg'
                                alt='char_1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char2.svg'
                                alt='char_2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char3.svg'
                                alt='char_3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char4.svg'
                                alt='char_4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Linea
                            </p>
                            <img
                                src='/images/ricercasemica/lines1.svg'
                                alt='lines1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines2.svg'
                                alt='lines2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines3.svg'
                                alt='lines3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines4.svg'
                                alt='lines4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Testo
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text1.svg'
                                alt='text1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text2.svg'
                                alt='text2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text3.svg'
                                alt='text3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text4.svg'
                                alt='text4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Compressione
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress1.svg'
                                alt='compress1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress2.svg'
                                alt='compress2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress3.svg'
                                alt='compress3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress4.svg'
                                alt='compress4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Evoluzione
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo1.svg'
                                alt='evo1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo2.svg'
                                alt='evo2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo3.svg'
                                alt='evo3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo4.svg'
                                alt='evo4' className="pieceImgWide"
                            />
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}